<template>
  <div v-if="editor" v-loading="loading">
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import Mention from "@tiptap/extension-mention";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-2";
import tippy from "tippy.js";
import { VueRenderer } from "@tiptap/vue-2";
import MentionList from "./MentionList.vue";
//import { mapGetters } from "vuex";
//import { postAPICall } from "../../../helpers/httpHelper";
export default {
  components: {
    EditorContent
  },
  computed: {
    //...mapGetters("entities", ["getEntityDataById"])
  },
  data() {
    return {
      editor: null,
      allFields: [],
      loading: false,
      allSteps: [],
      mentionableFields: [],
    };
  },
  props: ["formbuilder_id", "data", "fromNotifications"],
  async mounted() {
    this.loading = true;
    await this.getMentionableFields();
    this.loading = false;
  },
  methods: {
    async getMentionableFields() {
      let mentionable_fields = [];
      let fields = this.data?.is_anonymous ? ["EVENT_NAME"] : ["EVENT_NAME", "USER_NAME", "USER_EMAIL"];

      mentionable_fields = fields.map(field => {
        return {
          label: field.toLowerCase(),
          key: field
        };
      });

      this.mentionableFields = mentionable_fields;

      this.editor = new Editor({
        onUpdate: ({ editor }) => {
          if (this.fromNotifications) {
            const text = editor.getText();
            this.data.custom_message = text;
          } else {
            const json = editor.getJSON();
            this.data.custom_message = { ...json };
          }
        },
        extensions: [
          StarterKit,
          Mention.configure({
            HTMLAttributes: {
              class: "mention"
            },
            suggestion: {
              items: () => {
                return this.mentionableFields?.length ? this.mentionableFields : [];
              },

              render: () => {
                let component;
                let popup;

                return {
                  onStart: props => {
                    component = new VueRenderer(MentionList, {
                      parent: this,
                      propsData: props
                    });

                    if (!props.clientRect) {
                      return;
                    }

                    popup = tippy("body", {
                      getReferenceClientRect: props.clientRect,
                      appendTo: () => document.body,
                      content: component.element,
                      showOnCreate: true,
                      interactive: true,
                      trigger: "manual",
                      placement: "bottom-start"
                    });
                  },

                  onUpdate(props) {
                    component.updateProps(props);

                    if (!props.clientRect) {
                      return;
                    }

                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect
                    });
                  },

                  onKeyDown(props) {
                    if (props.event.key === "Escape") {
                      popup[0].hide();

                      return true;
                    }

                    return component.ref?.onKeyDown(props);
                  },

                  onExit() {
                    popup[0].destroy();
                    component.destroy();
                  }
                };
              }
            }
          })
        ],
        content: this.data.custom_message
      });
    },
    // async getAllSteps() {
    //   this.allSteps = this.data?.formbuilder_details?.steps;
    //   if (this.allSteps?.length) {
    //     this.allSteps.forEach(async step => {
    //       if (step.type == "ENTITY") {
    //         console.log("step.entity_id", step.entity);
    //         await this.fetchEntityDetails(step.entity);
    //       } else {
    //         await this.fetchTemplateStepDetails(step.template);
    //       }
    //     });
    //   }
    // },
    // async fetchTemplateStepDetails(id) {
    //   let result = await postAPICall("GET", `/template/${id}`);
    //   if (result?.data?.sections[0]?.fields) {
    //     this.allFields = [
    //       ...this.allFields,
    //       ...this.getTemplateFields(result.data, "")
    //     ];
    //   }
    // },
    // async fetchEntityDetails(id) {
    //   await this.$store.dispatch("entities/fetchEntityById", { entity_id: id });
    //   if (this.getEntityDataById && this.getEntityDataById.templates) {
    //     console.log("this.getEntityDataById", this.getEntityDataById);
    //     let entitySlug = this.getEntityDataById?.name;
    //     this.getEntityDataById.templates.forEach(template => {
    //       if (template?.templateInfo?.sections[0]?.fields) {
    //         this.allFields = [
    //           ...this.allFields,
    //           ...this.getTemplateFields(template?.templateInfo, entitySlug, id)
    //         ];
    //       }
    //     });
    //   }
    // },
    // getTemplateFields(data, entityName, entityId) {
    //   let fields = [];
    //   data.sections.forEach(e => {
    //     fields = [...fields, ...e.fields];
    //   });

    //   fields = fields.map(e => {
    //     e.template_id = data._id;
    //     //e.label = (entityName ? entityName + " - " : '') + data.name + " - " + e.key;
    //     e.label =
    //       (entityId ? entityId + "#" : "") +
    //       (this.fromNotifications ? data._id : data.slug) +
    //       "#" +
    //       e.key;
    //     e.template_key = data._id + "#" + e.key;
    //     e.entity_id = entityId ? entityId : "";
    //     e.template_name = data.name;
    //     return e;
    //   });
    //   console.log(" getTemplateFields callled final fieldsssssss", fields);
    //   return fields;
    // }
  }
};
</script>

<style lang="scss">
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

.mention {
  border: 1px solid #000;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
  box-decoration-break: clone;
}
</style>

<style lang="css">
.tippy-box {
  max-height: 40vh;
  overflow-y: auto;
  scrollbar-width: thin;
}
</style>
